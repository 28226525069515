import { Empty } from "antd";
/** Config */
import { UrlInternal } from "common/constants/endpoints";
import useWindowSize from "hooks/useWindowSize";
import moment from "moment";
import React, { useCallback, useRef, useState } from "react"; //lazy
import { useHistory } from "react-router-dom";
/**img */
import ImgGift from "assets/images/_main_slider/gift.png";
/** Ant Lib*/
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { getTimeRemaning } from "utils/getTime";
import Countdown from "react-countdown";
/** Utils */
import moneyConvert from "utils/moneyConvert";
/** Components*/
import CircleProgress from "../CircularProgress";
/** Style Local */
import * as S from "./style";
import { useTranslation } from "react-i18next";
import moneyConvertLang from "utils/moneyConvertLang";
import { formatPrice } from "utils/formatPrice";
import ReactPlayer from "react-player/lazy";
import UrlVideo from "assets/video/KakaoTalk_Video.mp4";
import { auto } from "@popperjs/core";

interface Props {
  data?: any;
}

//props: Props
const MainSlider = ({ data = [] }: Props) => {
  const history = useHistory();
  const slider: any = useRef<any>(null);
  const { width }: any = useWindowSize();
  const [showItem, setShowItem] = useState<any>({ show: false, item: "" });
  const { t } = useTranslation();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 500,
  };

  /** Next to slide */
  const nextToSlide = useCallback(() => {
    slider.current.slickNext();
  }, []);

  /** Back to slide */
  const backToSlide = useCallback(() => {
    slider.current.slickPrev();
  }, []);

  /** Show info on mobile size*/
  const showInfo = (show: any, item: number) => {
    setShowItem({ show, item });
  };

  const prefixO = (dataNumber: number) => {
    return dataNumber < 10 ? `0${dataNumber}` : dataNumber;
  };

  const renderer = ({ hours, minutes, seconds, completed }: any) => {
    if (completed) {
      return <></>;
    } else {
      return (
        <div>
          {hours <= 0 && minutes <= 0 && seconds <= 0 ? (
            <></>
          ) : (
            <span className="time-event">
              {" "}
              ({prefixO(hours)} : {prefixO(minutes)} : {prefixO(seconds)})
            </span>
          )}
        </div>
        // <span>
        //   {hours <= 0 && minutes <= 0 && seconds <= 0 ? <></> : prefixO(hours)}{" "}
        //   : {prefixO(minutes)} : {prefixO(seconds)}
        // </span>
      );
    }
  };

  const handleShowCountDown = (endDate: string) => {
    const remaningDay = getTimeRemaning(moment().toISOString(), endDate);

    if (remaningDay > 0) {
      return `${remaningDay}${t("D")}`;
    } else {
      return (
        <>
          {remaningDay}
          {t("D")}
          <Countdown date={new Date(endDate).getTime()} renderer={renderer} />
        </>
      );
    }
  };

  const rendererIsComming = ({
    hours,
    minutes,
    seconds,
    completed,
    days,
  }: any) => {
    if (completed) {
      return <>0{t("D")}</>;
    } else {
      return (
        <div>
          {hours <= 0 && minutes <= 0 && seconds <= 0 && days <= 0 ? (
            <>0{t("D")}</>
          ) : (
            <>
              {days > 0 ? (
                <span className="time-event">
                  {`${days}${t("D")}`} ({prefixO(hours)} : {prefixO(minutes)} :{" "}
                  {prefixO(seconds)})
                </span>
              ) : (
                <span className="time-event">
                  {prefixO(hours)} : {prefixO(minutes)} : {prefixO(seconds)}
                </span>
              )}
            </>
          )}
        </div>
      );
    }
  };

  const handleShowCountDownIsComming = (endDate: string) => {
    const remaningDay = getTimeRemaning(moment().toISOString(), endDate);

    return (
      <>
        {/* {remaningDay > 0 && <>{`${remaningDay}${t("D")}`}</>} */}

        <Countdown
          date={new Date(endDate).getTime()}
          renderer={rendererIsComming}
        />
      </>
    );
    // }
  };

  const handleCheckTimeEndEvent = (eventEndDate: string) => {
    if (eventEndDate) {
      let presentDate = moment(moment().toISOString(), "YYYY-MM-DD HH:mm:ss");
      let lastDate = moment(eventEndDate, "YYYY-MM-DD HH:mm:ss");
      let result = lastDate.diff(presentDate);
      return result <= 0 ? false : true;
    } else {
      return true;
    }
  };

  const renderDataSlider = () => {
    let dt = [];
    if (data) {
      for (let i = 0; i < data.length; i++) {
        if (handleCheckTimeEndEvent(data[i].eventEndDate)) {
          dt.push(data[i]);
        }
      }
    }
    return dt;
  };

  const checksizeChart = (v: any) => {
    const value = v?.isUpcomming
      ? 0
      : moment(v?.endNormalBuyAt) > moment()
      ? v?.v3TotalUnitReg * v?.unitPrice
      : 0;

    if (value >= 1000000000000) {
      return "15px";
    } else if (value >= 100000000000) {
      return "16px";
    } else if (value >= 10000000000) {
      return "18px";
    } else if (value >= 1000000000) {
      return "19px";
    } else {
      return "22px";
    }
  };

  return (
    <S.WrapperContainer>
      <div className="box-video">
        <ReactPlayer
          // key={id}
          url={UrlVideo}
          // style={{
          //   maxWidth: "100%",
          //   height: "100%",
          //   maxHeight: "200px",
          // }}
          playing
          loop
          muted={true}
        />
      </div>

      {renderDataSlider()?.length > 0 && (
        <S.Container className="main-slider">
          <Slider ref={slider} {...settings} useTransform={false}>
            {renderDataSlider()?.map((v: any, i: any) => (
              <S.SlideItem key={i}>
                <div
                  className={`item item-left ${
                    showItem?.show && showItem?.item === i ? "showInfo" : ""
                  }`}
                  // onClick={() => (width <= 768 ? showInfo(true, i) : () => {})}
                >
                  <div className="item-left__title type-gp">
                    {v?.isUpcomming ? "모집예정 청약" : "모집중인 청약"}
                  </div>
                  {/* <div className="item-left__title author">{v?.authorName}</div> */}
                  <div className="item-left__title">{v?.title}</div>
                  <div className="item-left__art">
                    {v?.thumbnail ? (
                      <img src={v?.thumbnail} alt="art" />
                    ) : (
                      <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description="No Image"
                      />
                    )}
                  </div>
                  <div className="item-left__size">
                    {v?.authorName}, {v?.artworkName}
                    {v?.artworkName && ","}
                  </div>
                  <div className="item-left__size">
                    {`${v?.artSize ? v?.artSize?.trim() + "," : ""} ${
                      v?.artMaterial
                        ? v?.artMaterial?.trim() + (v?.yearOfItem ? "," : "")
                        : ""
                    } ${v?.yearOfItem?.trim()}`}
                  </div>
                  {data.length > 1 && (
                    <div
                      className="ti ti-arrow-circle-left back"
                      onClick={backToSlide}
                    ></div>
                  )}
                  <div
                    className="open-information"
                    // onClick={() => showInfo(!showItem?.show, i)}
                    onClick={() =>
                      history.push(
                        `${
                          v?.isUpcomming
                            ? UrlInternal.GROUP_SALE_COMMING
                            : UrlInternal.GROUP_SALE_ON_ORDER
                        }/${v?.id}`
                      )
                    }
                  >
                    <span>청약 신청하기</span>
                    {/* <span className="ti ti-angle-double-right"></span> */}
                  </div>
                </div>
                <div className="item-line"></div>
                <div
                  className="item item-right"
                  onClick={() =>
                    width <= 768 ? showInfo(!showItem?.show, i) : () => {}
                  }
                >
                  <div className="item-right__title">
                    Offering
                    {/* {v?.isUpcomming ? "모집예정 청약" : "모집중인 청약"} */}
                  </div>
                  <div className="item-right__tab">
                    <div className="item-right__tab-item active">
                      {/* {changLangSubCategory(v?.subCategory)} */}
                      <div>
                        <div style={{ fontSize: 16 }}>Offering deadline</div>
                        <div>
                          {moment(
                            v?.gpVer === "V3" ? v?.endNormalBuyAt : v?.endDate
                          ).format("YYYY. MM. DD")}
                        </div>
                      </div>
                    </div>

                    <div className="item-right__tab-item">
                      <div>
                        <div style={{ fontSize: 16 }}>Remaining duration</div>
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          {v?.isUpcomming
                            ? handleShowCountDownIsComming(v?.startDate)
                            : // ? `${moment(v?.startDate).diff(moment(), "d")} 일`
                              handleShowCountDown(
                                v?.gpVer === "V3"
                                  ? v?.endNormalBuyAt
                                  : ["EVENT_RB", "EVENT_ARB"].includes(
                                      v?.eventType ?? ""
                                    )
                                  ? v?.eventEndDate
                                  : v?.endDate
                              )}
                        </div>
                      </div>

                      {/* {v?.isUpcomming
                        ? `남은 기간\t:\t`
                        : `${t("REMAINING_TIME")}\t:\t`} */}
                    </div>
                  </div>
                  {["EVENT_RB", "EVENT_ARB"].includes(v?.eventType ?? "") ? (
                    <>
                      <img src={ImgGift} alt="img gift" />
                      <div className="item-right__box-price">
                        <div className="item-right__box-price--title">
                          {t("HOME_PRICE")}
                        </div>
                        <div className="item-right__box-price--price">
                          {moneyConvertLang(v?.target)}
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="item-right__chart">
                        {v?.gpVer === "V3" ? (
                          <CircleProgress
                            percentage={100}
                            content={
                              <S.Content>
                                <span
                                  className="percentage"
                                  style={{ fontSize: checksizeChart(v) }}
                                >
                                  {v?.isUpcomming
                                    ? "0"
                                    : moment(v?.endNormalBuyAt) > moment()
                                    ? `${formatPrice(
                                        v?.v3TotalUnitReg * v?.unitPrice
                                      )}`
                                    : "0"}
                                </span>
                                <span className="chart-line"></span>
                                <span className="chart-content">
                                  Cumulative subscription amount
                                </span>
                              </S.Content>
                            }
                            color="#0E7BB9"
                          />
                        ) : (
                          <CircleProgress
                            percentage={v?.targetPercent}
                            content={
                              <S.Content>
                                <span className="percentage">
                                  {v?.targetPercent}%
                                </span>
                                <span className="chart-line"></span>
                                <span className="chart-content">
                                  {t("SLIDER_PROGRESS1")}
                                </span>
                              </S.Content>
                            }
                            color="#0E7BB9"
                          />
                        )}
                      </div>
                      <div className="item-right__price">
                        <div className="item-right__price-left">
                          <span className="text-price">Amount per share</span>
                          <span className="value-price">
                            {moneyConvertLang(v?.unitPrice)}
                          </span>
                        </div>
                        <div className="item-right__price-line"></div>
                        <div className="item-right__price-right">
                          <span className="text-price">Total IPO amount</span>
                          <span className="value-price">
                            {moneyConvertLang(v?.target)}
                          </span>
                        </div>
                      </div>
                    </>
                  )}

                  <button
                    className="item-right__btn"
                    onClick={() =>
                      history.push(
                        `${
                          v?.isUpcomming
                            ? UrlInternal.GROUP_SALE_COMMING
                            : UrlInternal.GROUP_SALE_ON_ORDER
                        }/${v?.id}`
                      )
                    }
                  >
                    Go to Offering
                    {` `}
                    <i className="ti ti-angle-right"></i>
                  </button>
                  {data.length > 1 && (
                    <div
                      className="ti ti-arrow-circle-right next"
                      onClick={nextToSlide}
                    ></div>
                  )}
                </div>
              </S.SlideItem>
            ))}
          </Slider>
        </S.Container>
      )}
    </S.WrapperContainer>
  );
};

export default MainSlider;
