import { mainAxios } from "libs/axios";

/**Register Props type */
interface RegisterProps {
  userName: string;
  password: string;
  displayName: string;
  email: string;
  joinType: string;
  emailReceive: boolean;
  smsReceive: boolean;
  phone: string;
  address1?: string;
  address2?: string;
  zipCode?: string;
  bankName: string;
  bankAccount: string;
  bankHolderName: string;
  userRole: string;
  companyBc?: string;
  companyBlUrl?: string;
  companyName?: string;
  companyCEO?: string;
  companyField?: string;
  companyZipCode?: string;
  companyAddr1?: string;
  companyStatus?: string;
  companyAddr2?: string;
  snsId?: string;
  gender?: string;
  birthDate?: string;
  invoiceType?: string;
  invoiceInfo?: string;
  tokenVersionId?: string;
}

export const registerApi = (payload: RegisterProps) => {
  return mainAxios.request({
    methodType: "POST",
    url: `/auth/sign-up`,
    payload: payload,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
  });
};

/**Login Props type */
interface LoginProps {
  userName: string;
  password: string;
}

export const loginApi = (payload: LoginProps) => {
  return mainAxios.request({
    methodType: "POST",
    url: `/auth/sign-in-new`,
    payload: payload,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
  });
};

export const forgotPassword = (payload: any) => {
  return mainAxios.request({
    methodType: "POST",
    url: `/auth/forgot-password`,
    payload: payload,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
  });
};

export const resetPasswordApi = (payload: any) => {
  return mainAxios.request({
    methodType: "POST",
    url: `/auth/sign-in`,
    payload: payload,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
  });
};

/** Get User Info */
export const getUserInfo = () => {
  return mainAxios.request({
    methodType: "GET",
    url: `/user/details`,
    requiresToken: true,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
  });
};

/**Find id props type */
interface findIdProps {
  email: string;
}

export const findIdApi = (payload: findIdProps) => {
  return mainAxios.request({
    methodType: "POST",
    url: `/auth/find-id`,
    payload: payload,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
  });
};

/**Find id props type */
interface findPasswordProps {
  email: string;
  userName: string;
}
export const findPasswordApi = (payload: findPasswordProps) => {
  return mainAxios.request({
    methodType: "POST",
    url: `/auth/recovery-pw`,
    payload: payload,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
  });
};

export const checkExist = async (fieldCheck: string, valueCheck: string) => {
  return mainAxios.postRequest({
    requiresToken: true,
    url: "/auth/check-exists",
    payload: {
      fieldCheck,
      valueCheck,
    },
  });
};

/**login sns props type */
interface LoginSnsProps {
  snsType: string;
  snsAccessTokenOrCode: string;
}

export const loginSNS = (payload: LoginSnsProps) => {
  return mainAxios.request({
    methodType: "POST",
    url: `/auth/sign-in-sns-new`,
    payload: payload,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
  });
};

/**change password props type */
interface changePasswordProps {
  oldPassword: string;
  newPassword: string;
}

export const changePasswordApi = (payload: changePasswordProps) => {
  return mainAxios.request({
    methodType: "POST",
    url: `/auth/change-password`,
    payload: payload,
    requiresToken: true,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
  });
};

export const getDataStatisticUser = (acToken: string) => {
  return mainAxios.request({
    methodType: "GET",
    url: `/user/statistic-purchase-gp-main-category`,
    requiresToken: true,
    config: {
      headers: {
        contentType: "application/json",
        Authorization: `Bearer ${acToken}`,
      },
    },
  });
};
