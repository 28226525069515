import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as S from "./style";

function FooterMain() {
  const history = useHistory();
  const { t } = useTranslation();
  const { dataCategoriesTerm } = useSelector(
    (state: any) => state.dataCategoriesTerm
  );

  return (
    <S.Wrapper id="footer">
      <S.Container>
        <div className="step">
          <S.Title>COMPANY</S.Title>
          <S.Box>
            <S.Text>Yeolmaecompany Co., Ltd</S.Text>
            <S.Text>CEO: Kim Jae Wook</S.Text>
            <S.Text>Address</S.Text>
            <S.Text>
              Office 1: Youngdongdae-ro 407, Gangnam, Seoul, S.Korea 06182
            </S.Text>
            <S.Text>
              Office 2: Heemang-ro 46gil 45-11. Baebangeup, Asansi, S.Korea
              34170
            </S.Text>
          </S.Box>

          <S.Box className="mt-18">
            <S.Text>
              Online retail business number: 2021-Chungnamasan-0558
            </S.Text>
            <S.Text>Data protection officer: Kim Jae Wook</S.Text>
          </S.Box>

          <S.Text className="text-modified">
            Business registarion: 664-88-00573
          </S.Text>

          <S.ContainerTerm>
            {dataCategoriesTerm?.map((dt: any, i: any) => (
              <div key={i} onClick={() => history.push(dt.location)}>
                {dt.title}
              </div>
            ))}
          </S.ContainerTerm>
          <S.TextMin>© Yeolmaecompany. all rights reserved</S.TextMin>
        </div>

        <div className="step">
          <S.Title className="title-modified">CONTACT</S.Title>
          <S.Box>
            <S.Text className="max-content">
              For business partnerships and general inquiries
            </S.Text>
            <S.Text>info@artnguide.com</S.Text>
          </S.Box>
          <S.Box className="mg">
            <S.Text>For customer service inquiries</S.Text>
            <S.Text>cs@artnguide.com</S.Text>
          </S.Box>

          <S.Title className="title-modified">Operating hours</S.Title>
          <S.Text>
            Weekdays 10:00 am to 06:00 pm (Lunch break from 12:00 pm to 01:00
            pm)
          </S.Text>

          <S.ContainerTerm className="isMobile">
            {dataCategoriesTerm?.map((dt: any, i: any) => (
              <div key={i} onClick={() => history.push(dt.location)}>
                {dt.title}
              </div>
            ))}
          </S.ContainerTerm>
          <S.TextMin className="isMobile">
            © Yeolmaecompany. all rights reserved
          </S.TextMin>
        </div>
      </S.Container>
    </S.Wrapper>
  );
}

export default FooterMain;
