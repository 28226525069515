import { Button, Form, Input, message } from "antd";
import { findIdApi, findPasswordApi } from "apis";
import { Tabs } from "components";
import React, { useState } from "react";
import ReactDOM from "react-dom";
import * as S from "./style";
import { ReactComponent as IconClose } from "assets/icons/close.svg";
import { useHistory } from "react-router-dom";
import { UrlInternal } from "common/constants/endpoints";

interface ModalProps {
  visible: boolean;
  // onOk?: () => void;
  onCancel?: () => void;
  openModalSuccess?: (e: string) => void;
}

const listTabs = ["Find ID", "Find Password"];

const ModalFindIdPass = ({
  visible,
  openModalSuccess,
  onCancel,
}: ModalProps) => {
  const [tabActive, setTabActive] = useState<number>(0);
  const history = useHistory();

  const onFinish = async (values: any) => {
    if (tabActive === 0) {
      try {
        const res: any = await findIdApi({
          email: values.email,
        });
        if (res.result) {
          onCancel?.();
          openModalSuccess?.("FIND_ID");
        }
      } catch (error) {}
    } else {
      try {
        const res: any = await findPasswordApi({
          email: values.emailFindPass,
          userName: values.userName,
        });
        if (res.result) {
          onCancel?.();
          openModalSuccess?.("FIND_PW");

          // history.push(UrlInternal.NICE_FORM, {
          //   dataEncrypted: res.data.dataEncrypted,
          //   integrityValue: res.data.integrityValue,
          //   tokenVersionId: res.data.tokenVersionId,
          // });
        }
      } catch (error) {}
      // setTabActive(0);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    let feildErr: any = errorInfo?.errorFields[0]?.name[0];
    const feildDom: any = document?.querySelector(`input[name='${feildErr}']`);
    feildDom?.focus();
  };
  return visible
    ? ReactDOM.createPortal(
        <>
          <S.ModalOverlay />
          <S.ModalWrapper aria-modal aria-hidden tabIndex={-1} role="dialog">
            <S.ModalClose
              onClick={() => {
                onCancel?.();
                setTabActive(0);
              }}
            />
            <S.Modal>
              {onCancel && (
                <S.ModalCloseButton
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    onCancel?.();
                    setTabActive(0);
                  }}
                >
                  <IconClose />
                </S.ModalCloseButton>
              )}
              <S.WrapperTabs>
                <Tabs
                  listTabs={listTabs}
                  handleActive={(e: number) => setTabActive(e)}
                />
              </S.WrapperTabs>
              <S.WrapperForm>
                <Form
                  name="basic"
                  initialValues={{ remember: true }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                  layout="vertical"
                >
                  {tabActive === 1 ? (
                    <>
                      <Form.Item
                        label="ID"
                        name="userName"
                        rules={[
                          { required: true, message: "아이디를 입력해주세요" },
                        ]}
                      >
                        <Input name="userName" />
                      </Form.Item>
                      <Form.Item
                        label="Email"
                        name="emailFindPass"
                        rules={[
                          {
                            required: true,
                            message: "이메일 주소를 입력해주세요.",
                          },
                          {
                            type: "email",
                            message: "이메일 주소 형식이 잘못되었습니다",
                          },
                        ]}
                      >
                        <Input name="emailFindPass" />
                      </Form.Item>
                    </>
                  ) : (
                    <Form.Item
                      label="Email"
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "이메일 주소를 입력해주세요.",
                        },
                        {
                          type: "email",
                          message: "이메일 주소 형식이 잘못되었습니다",
                        },
                      ]}
                    >
                      <Input name="email" />
                    </Form.Item>
                  )}

                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Verify membership
                    </Button>
                  </Form.Item>
                </Form>
              </S.WrapperForm>
              {tabActive === 0 ? (
                <S.Note>
                  The ID will be sent to the email address you registeed with
                  us.
                </S.Note>
              ) : (
                <S.Note>
                  The password will be sent to the email address you registeed
                  with us.
                </S.Note>
              )}
            </S.Modal>
          </S.ModalWrapper>
        </>,
        document.body
      )
    : null;
};

export default ModalFindIdPass;
