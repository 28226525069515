import { Modal } from "antd";
import React, { ReactNode } from "react";
import * as S from "./style";
export interface PopupProps {
  visible: boolean;
  setVisible: () => void;
  content?: ReactNode;
  onlyOk?: boolean;
  okEvent?: () => void;
  isOtherPage?: boolean;
  customClassName?: string;
  isNotMarginTop?: boolean;
  btnMin?: boolean;
  mypage?: boolean;
  noBtn?: boolean;
  className?: string;
  textCancel?: string;
  textOk?: string;
  noFixedBtn?: boolean;
}

const Popup: React.FC<PopupProps> = ({
  visible,
  setVisible,
  content,
  onlyOk,
  okEvent,
  isOtherPage,
  customClassName,
  isNotMarginTop = false,
  btnMin,
  mypage,
  noBtn,
  className,
  textCancel,
  textOk,
  noFixedBtn = false,
}) => {
  return (
    <S.ModalPopup
      visible={visible}
      // width={400}
      width={"max-content"}
      top={isOtherPage ? "100px" : ""}
      closable={false}
      footer={null}
      onCancel={() => setVisible()}
      className={`${className} ${noFixedBtn ? "noFixedBtn" : ""}`}
      centered
    >
      <S.Container className={isOtherPage ? `otherpage-popup-container` : ""}>
        {content}
        {!noBtn && (
          <S.WrapperButton
            isNotMarginTop={isNotMarginTop}
            mypage={mypage}
            onlyOk={onlyOk}
            className={btnMin ? "min" : "wrapper-btn"}
          >
            {!onlyOk ? (
              <>
                <S.ButtonCancel
                  className={isOtherPage ? "otherpage-popup-cancel" : ""}
                  onClick={() => setVisible()}
                >
                  {textCancel || "Cancel"}
                </S.ButtonCancel>
                <S.ButtonConfirm id="btn" onClick={okEvent}>
                  {textOk || "Confirm"}
                </S.ButtonConfirm>
              </>
            ) : (
              <S.ButtonConfirm
                // className={btnMin ? "min" : ""}
                onClick={okEvent}
              >
                Confirm
              </S.ButtonConfirm>
            )}
          </S.WrapperButton>
        )}
      </S.Container>
    </S.ModalPopup>
  );
};

export default Popup;
