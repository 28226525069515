import React, { useEffect, useState } from "react"; //lazy
// import { useHistory } from "react-router-dom";

/** Components */
import { ErrorSpan, ProgressBar, Select } from "components";

import ImgAnswer from "assets/images/_group_sale/answer.png";

/** Style Local */
import { Empty, Input, InputNumber } from "antd";
import { UrlInternal } from "common/constants/endpoints";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import moneyConvertLang from "utils/moneyConvertLang";
import * as S from "./style";
import moment from "moment";
import moneyConvert from "utils/moneyConvert";
import { formatPrice } from "utils/formatPrice";
import SelectInputAndPick from "components/SelectInputAndPick";

import { useCountdown } from "hooks/useCountdown";
import { useCountdownDetailGp } from "hooks/useCountdownDetailGp";
import InputNumberCustom from "components/InputNumberCustom";
import InputNumberNew from "components/InputNumberNew";

interface Props {
  artName?: string;
  artProgress?: number;
  artTotalAmount?: number;
  artTargetAmount?: number;
  artTimeRemaining?: number;
  actionButtonDisable?: boolean;
  actionButtonTitle?: string;
  actionButtonEvent?: () => void;
  listSubImage?: any;
  goToDetail?: () => void;
  notButton?: boolean;
  errorContent?: string;
  maxStock?: number;
  maxUnit?: number;
  getQuantity?: any;
  pageSaleEnd?: boolean;
  waitingOrder?: boolean;
  eventType?: string;
  eventEndAt?: string;
  endAt?: string;
  startAt?: string;
  artTimeRemainingEvent?: number;
  artTimeRemainingV3?: number;
  data?: any;
  getTypeBuyGp?: (e: string) => void;
}

function CardDetailOnGoing({
  artName,
  artProgress = 0,
  artTotalAmount = 0,
  artTargetAmount = 0,
  artTimeRemaining = 0,
  actionButtonDisable = false,
  actionButtonTitle,
  actionButtonEvent,
  listSubImage = [],
  goToDetail,
  notButton,
  errorContent,
  maxStock = 0,
  getQuantity,
  maxUnit,
  pageSaleEnd,
  waitingOrder,
  eventType,
  eventEndAt,
  endAt,
  artTimeRemainingEvent = 0,
  artTimeRemainingV3 = 0,
  startAt,
  data,
  getTypeBuyGp,
}: Props) {
  const history = useHistory();
  const [currentItem, setCurrentItem] = useState<any>({
    id: 0,
    data: listSubImage[0] ?? "",
  });
  const { t } = useTranslation();
  const [qty, setQty] = useState(1);

  const [days, hours, minutes, seconds] = useCountdownDetailGp(
    new Date(data.endNormalBuyAt ?? new Date()).getTime()
  );

  const dataCountDown = useCountdown(
    new Date(endAt ?? new Date()).getTime(),
    artTimeRemaining
  );

  const checkDisabledSelect =
    (!data?.canOrderNormal && !data?.canOrderWaiting) ||
    (days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0) ||
    (data?.gpVer === "V3"
      ? data?.stockForBuy
      : data?.canOrderWaiting
      ? data?.maxUnitForU1
      : data?.stockForBuy,
    data?.maxUnitForU1 <= 0);

  useEffect(() => {
    setCurrentItem({
      id: 0,
      data: listSubImage[0] ?? "",
    });
  }, [listSubImage]);

  const prefixO = (dataNumber: number) => {
    return dataNumber < 10 ? `0${dataNumber}` : dataNumber;
  };
  useEffect(() => {
    if (
      dataCountDown[0] <= 0 &&
      dataCountDown[1] <= 0 &&
      dataCountDown[2] <= 0 &&
      dataCountDown[3] <= 0 &&
      !pageSaleEnd
    ) {
      history.push(UrlInternal.GROUP_SALE_END);
    }
  }, [dataCountDown, pageSaleEnd]);
  // useEffect(() => {
  //   if (
  //     days <= 0 &&
  //     hours <= 0 &&
  //     minutes <= 0 &&
  //     seconds <= 0 &&
  //     // eventType !== "EVENT_RB" &&
  //     // eventType !== "EVENT_ARB" &&
  //     !pageSaleEnd
  //   ) {
  //     history.push(UrlInternal.GROUP_SALE_END);
  //   }
  // }, [days, hours, minutes, seconds, eventType, pageSaleEnd]);

  return (
    <S.SaleTogetherContainer className="sale-group">
      <S.ListImage>
        {/* <div> */}
        {listSubImage?.map((v: any, i: number) => (
          <S.ItemtImage
            className={currentItem.id === i ? "active" : ""}
            key={i}
            src={v}
            alt="image-item"
            onClick={() => setCurrentItem({ id: i, data: v })}
          />
        ))}
        {/* </div> */}
      </S.ListImage>
      <S.InfoData className="content-data">
        <S.LeftInfo>
          {/**bg={currentItem.data} */}
          {currentItem.data ? (
            <S.ArtImage
              src={currentItem.data}
              alt="art-img"
              onClick={goToDetail}
            />
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No Image"
            />
          )}
        </S.LeftInfo>
        <S.Content>
          <div className="box-content">
            <div className="box-content-item">
              <div className="title">{data?.authorName},</div>
              <div className="title2">{data?.title}</div>
            </div>
            <div className="box-content2">
              <div>
                <div className="item-content2">
                  <span className="text-normal">Price per share</span>
                  <span className="text-bold text-bold2">
                    {formatPrice(data?.unitPrice)} USD
                  </span>
                </div>
                <div className="item-content2">
                  <span className="text-normal">Total IPO amount</span>
                  <span className="text-bold">
                    {formatPrice(data?.target)} USD{" "}
                  </span>
                </div>

                <div className="text-small" style={{ textAlign: "right" }}>
                  No. of shares: {formatPrice(data?.target / data?.unitPrice)}
                </div>
              </div>
              <div className="box-timer">
                {!(days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0) && (
                  <div className="time">
                    {/* <span>Remaining time</span>{" "} */}
                    <span className="time-countdown">
                      {days} day {prefixO(hours)}:{prefixO(minutes)}:
                      {prefixO(seconds)}
                    </span>{" "}
                    <span>remaining until the deadline.</span>
                  </div>
                )}
                <div className="time-end">
                  (<span>Offering Closed</span> :{" "}
                  {moment(data?.endNormalBuyAt).format("YYYY.MM.DD HH:mm")})
                </div>
              </div>
              <div className="price">
                <div>Cumulative Subscription Amount</div>
                <div>
                  <span className="price-bold">
                    {formatPrice(data?.v3TotalUnitReg * data?.unitPrice)}
                  </span>{" "}
                  <span>USD</span>
                </div>
              </div>
            </div>
            <div className="box-select">
              <div className="item-select">
                <div className="item-select-text">No. of shares</div>
                <InputNumberNew
                  max={99999999999999999999999999}
                  // max={Math.min(
                  //   data?.gpVer === "V3"
                  //     ? data?.stockForBuy
                  //     : data?.canOrderWaiting
                  //     ? data?.maxUnitForU1
                  //     : data?.stockForBuy,
                  //   data?.maxUnitForU1
                  // )}
                  disabled={checkDisabledSelect}
                  onChange={(e) => {
                    getQuantity(e);
                    setQty(e);
                  }}
                  value={qty}
                  handleUp={() => {
                    let value = qty + 1;
                    getQuantity(value);
                    setQty(value);
                  }}
                  handleDown={() => {
                    let value = qty - 1;
                    getQuantity(value);
                    setQty(value);
                  }}
                />
                {/* <div className="item-select-qty">(삭제)</div> */}
              </div>
              <div className="note-select">
                *Maximum{" "}
                {formatPrice(
                  Math.min(
                    data?.gpVer === "V3"
                      ? data?.stockForBuy
                      : data?.canOrderWaiting
                      ? data?.maxUnitForU1
                      : data?.stockForBuy,
                    data?.maxUnitForU1
                  )
                )}{" "}
                shares{" "}
              </div>
            </div>
          </div>

          <div className="box-total">
            <div className="title-total">Total Price :</div>
            <div className="box-total-item">
              <div className="sub-total">Total {qty} share</div>
              <div>
                <span className="price-total">
                  {formatPrice(data?.unitPrice * qty)}
                </span>
                <span className="value-price">USD</span>
              </div>
            </div>
          </div>

          <S.ActionButton
            className="action-button"
            onClick={() => {
              actionButtonEvent?.();
              getTypeBuyGp?.("NORMAL");
            }}
            disabled={
              (!data?.canOrderNormal && !data?.canOrderWaiting) ||
              (days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0)
            }
          >
            Go to Offering
          </S.ActionButton>
        </S.Content>
      </S.InfoData>
    </S.SaleTogetherContainer>
  );
}

export default CardDetailOnGoing;
