import { notification } from "antd";
import "antd/dist/antd.css";
import { initForFetchEventSource } from "apis/fetchEventSource";
import axios from "axios";
/** Untils */
import { UrlInternal } from "common/constants/endpoints";
import GlobalStyles from "common/style/GlobalStyle";
import Normalize from "common/style/Normalize";
import defaultTheme from "common/style/themes/default";
import { FooterPage, PopupHandleAdmin } from "components";
import React, { lazy, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  // Route,
  Switch,
  withRouter,
} from "react-router-dom";
/** Router Global */
import { PrivateRoute, PublicRoute } from "router";
import rootAction from "store/actions";
import { ThemeProvider } from "styled-components";
import ScrollToTop from "utils/ScrollToTop";
import { getLocalStorage } from "utils/localStorage";
import { BLOCK_IP } from "utils/localStorage/type";

/** Page */
const Home = lazy(() => import("pages/Home"));
const Login = lazy(() => import("pages/Login"));
// const CustomService = lazy(() => import("pages/CustomService"));

const AgreeTermsRegister = lazy(() => import("pages/Register/AgreeTerms"));
const PersonalInfoRegister = lazy(() => import("pages/Register/PersonalInfo"));
const CompanyRegister = lazy(() => import("pages/Register/Company"));

const GroupSale = lazy(() => import("pages/GroupSale"));

const OwnerShip = lazy(() => import("pages/OwnerShip"));
const PurchaseSummary = lazy(() => import("pages/OwnerShip/PurchaseSummary"));
const PageNotFound = lazy(() => import("pages/PageNotFound"));

const RegisterAppointment = lazy(
  () => import("pages/Appointment/RegisterAppointment")
);

const MyPage = lazy(() => import("pages/MyPage"));

const ArtMarket = lazy(() => import("pages/ArtMarket"));

const FindIdPassword = lazy(() => import("pages/FindIdPassword"));

/** Link Page */
const LinkAgreementPage = lazy(() => import("pages/LinkPage/Agreement"));
const LinkBuyerPage = lazy(() => import("pages/LinkPage/Buyer"));
const LinkSellerPage = lazy(() => import("pages/LinkPage/Seller"));
const LinkPrivacyPage = lazy(() => import("pages/LinkPage/Privacy"));
const LinkProduct = lazy(() => import("pages/LinkPage/Product"));
const LinkAgreeTerm = lazy(() => import("pages/LinkPage/LinkAgreeTerm"));

const NaverSnS = lazy(() => import("pages/Login/naverSns"));
const KakaoSnS = lazy(() => import("pages/Login/kakaoSns"));
const GoogleSnS = lazy(() => import("pages/Login/googleSns"));
const FacebookSns = lazy(() => import("pages/Login/facebookSns"));
const SnsKakaoNaver = lazy(() => import("pages/Login/SnsKakaoNaver"));

const CustomerService = lazy(() => import("pages/CustomService"));

const NiceVerify = lazy(() => import("pages/Register/niceVerify"));
const FormNiceApi = lazy(() => import("pages/FormNiceApi"));

const Temp = lazy(() => import("pages/Temp"));
const TempDetail = lazy(() => import("pages/Temp/TempDetail"));
const CollectUserInformation = lazy(
  () => import("pages/CollectUserInformation")
);
const Maintenance = lazy(() => import("pages/Maintenance"));
const VoteHoldingProduct = lazy(() => import("pages/VoteHoldingProduct"));

// const DataIP = [
//   "14.232.244.55",
//   // "113",
//   // "121.162.201.165",
//   // "210.245.54.86",
//   // "14.52.169.251",
//   // "112.166.81.190",
//   // "222.114.95.50",
//   // "185.94.189.202",
//   // //ip company
//   // "14.232.244.55",
// ];

const App: React.FC = (props: any) => {
  const dispatch = useDispatch();
  let blockIp = getLocalStorage(BLOCK_IP, 0);

  // const [ip, setIP] = useState(false);

  // //creating function to load ip address from the API
  // const getData = async () => {
  //   const res = await axios.get("https://geolocation-db.com/json/");
  //   if (DataIP.includes(res.data.IPv4)) {
  //     setIP(true);
  //   }
  // };

  // useEffect(() => {
  //   getData();
  // }, []);

  useEffect(() => {
    // dispatch(rootAction.getCategoriesMarket.getDataMarket());
    if (!blockIp) {
      dispatch(rootAction.getCategoriesTerm.getCategoriesTerm());
      initForFetchEventSource();
    }
  }, [blockIp]);

  return (
    <ThemeProvider theme={defaultTheme}>
      {/* {ip ? ( */}
      <>
        {!blockIp && <PopupHandleAdmin />}
        <Normalize />
        <GlobalStyles />
        <ScrollToTop />
        <Switch>
          <PublicRoute exact path={UrlInternal.HOME} component={Home} />

          {/**login , register */}
          <PublicRoute exact path={UrlInternal.LOGIN} component={Login} />

          <PublicRoute
            exact
            path={UrlInternal.LOGIN_NAVER_SNS}
            component={NaverSnS}
          />
          <PublicRoute
            exact
            path={UrlInternal.LOGIN_KAKAO_SNS}
            component={KakaoSnS}
          />
          <PublicRoute
            exact
            path={UrlInternal.LOGIN_GOOGLE_SNS}
            component={GoogleSnS}
          />
          <PublicRoute
            exact
            path={UrlInternal.LOGIN_FACEBOOK_SNS}
            component={FacebookSns}
          />

          <PublicRoute
            exact
            path={UrlInternal.SNS_KAKAO_NAVER}
            component={SnsKakaoNaver}
          />

          <PublicRoute
            exact
            path={UrlInternal.REGISTER_AGREE_TERMS}
            component={AgreeTermsRegister}
          />
          <PublicRoute
            exact
            path={UrlInternal.REGISTER_PERSONAL_INFO}
            component={PersonalInfoRegister}
          />
          <PublicRoute
            exact
            path={UrlInternal.REGISTER_COMPANY}
            component={CompanyRegister}
          />

          <PublicRoute
            exact
            path={UrlInternal.FIND_ID_PASSWORD}
            component={FindIdPassword}
          />

          {/**CUSTOMER SERVICE */}
          <PublicRoute
            exact
            path={UrlInternal.CUSTOMER_SERVICE}
            component={CustomerService}
          />

          {/** Group Sale */}
          <PublicRoute
            exact
            path={UrlInternal.GROUP_SALE}
            component={GroupSale}
          />

          {/** Owner ship */}
          {/* <PublicRoute
            exact
            path={UrlInternal.OWNERSHIP}
            component={OwnerShip}
          /> */}

          <PublicRoute
            exact
            path={UrlInternal.PURCHASE_SUMMARY}
            component={PurchaseSummary}
          />

          <PublicRoute
            exact
            path={UrlInternal.REGISTER_APPOINTMENT}
            component={RegisterAppointment}
          />
          {/** My page */}
          <PrivateRoute exact path={UrlInternal.MYPAGE} component={MyPage} />

          {/** Art Market */}
          <PublicRoute
            exact
            path={UrlInternal.ART_MARKET}
            component={ArtMarket}
          />

          {/** Link Page */}
          {/* <PublicRoute
            exact
            path={UrlInternal.ART_LINK_AGREEMENT}
            component={LinkAgreementPage}
          />
          <PublicRoute
            exact
            path={UrlInternal.ART_LINK_BUYER}
            component={LinkBuyerPage}
          />
          <PublicRoute
            exact
            path={UrlInternal.ART_LINK_SELLER}
            component={LinkSellerPage}
          />
          <PublicRoute
            exact
            path={UrlInternal.ART_LINK_PRIVACY}
            component={LinkPrivacyPage}
          />
          <PublicRoute
            exact
            path={UrlInternal.ART_LINK_PRODUCT}
            component={LinkProduct}
          /> */}
          <PublicRoute
            exact
            path={UrlInternal.ART_AGREE_TERM}
            component={LinkAgreeTerm}
          />

          {/**nice api */}
          <PublicRoute
            exact
            path={UrlInternal.NICE_VERIFY_RESULT}
            component={NiceVerify}
          />
          <PublicRoute
            exact
            path={UrlInternal.NICE_FORM}
            component={FormNiceApi}
          />

          <PublicRoute exact path={UrlInternal.TEMP} component={Temp} />
          <PublicRoute
            exact
            path={UrlInternal.TEMP_DETAIL_ID}
            component={TempDetail}
          />

          <PublicRoute
            exact
            path={UrlInternal.COLLECT_USER_INFORMATION}
            component={CollectUserInformation}
          />
          <PublicRoute
            exact
            path={UrlInternal.VOTE_HOLDING_PRODUCT}
            component={VoteHoldingProduct}
          />

          <PublicRoute
            exact
            path={UrlInternal.MAINTENANCE}
            component={Maintenance}
          />

          <PublicRoute exact path={"*"} component={PageNotFound} />
        </Switch>
        {!blockIp && <FooterPage />}
      </>
      {/* ) : (
        <PublicRoute exact path={"*"} component={Maintenance} />
      )} */}
    </ThemeProvider>
  );
};

export default withRouter(App);
