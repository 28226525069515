import { getLocalStorage } from "utils/localStorage";
import { LANG } from "utils/localStorage/type";
import moneyConvert from "utils/moneyConvert";

const moneyConvertLang = (price?: number) => {
  const langMultiple = getLocalStorage(LANG, 0);
  if (langMultiple !== "en-US") {
    return moneyConvert(price ?? 0, true) + "USD";
  } else {
    return "USD" + " " + moneyConvert(price ?? 0, true);
  }
};

export default moneyConvertLang;
